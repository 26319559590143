import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationCrosshairs, faTowerBroadcast, faBell, faSquareH,
} from '@fortawesome/free-solid-svg-icons';

function Safety() {
  return (
    <section className="section-safety bg-light" id="safety">
      <div className="safety-container">
        <div className="rowS mt-5">
          <div className="col-lg-12">
            <div className="text-center">
              <h2 className="whiteText">
                <span className="fw-bold whiteText">
                  Safety, Security & Emergency Kits
                  <span className="beta-tag">Beta</span>
                </span>
              </h2>
              <p className="text-muted mx-auto section-subtitle mt-3">
                At efoyy, safety is our primary responsibility.
                We are dedicated to ensuring the well-being of everyone who uses our services.
              </p>

            </div>
          </div>
          <div className="col-lg-12">

            <div className="text-center mt-4">
              <button type="button" className="primary-button">
                Learn More
              </button>
            </div>
            <div className="text-center-safety security-msg">

              {/* <p className="text-muted mx-auto section-subtitle mt-3 security-msg">
                Our rider and driver apps meet the rigorous security standards set
                by the Information Network Security Administration (INSA).
              </p> */}

            </div>
          </div>
          <div className="col-lg-12">
            <div className=" text-center">

              {/* <p className=" mx-auto  m-5 security-msg">
                We ensure the utmost security for your documents with advanced encryption
                in transit using SSL/TLS, secure storage locations,and exclusive access controls,
                keeping your sensitive information protected and accessible only to you.
              </p> */}

            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-6 col-lg-3">
            <div className="text-center safety-box-safety rounded p-4 mt-4">

              <div className="mt-4">
                <h5 className="mb-0 whiteText">
                  {'   '}
                  <span className="safetyIconStyle-safety">
                    <FontAwesomeIcon icon={faLocationCrosshairs} aria-label="About" />
                  </span>
                  Live Location Sharing

                </h5>
                <div className="services-title-border" />
                <p className="text-muted mt-3">
                  Feel unsafe? You can instantly share your
                  live location with your emergency contacts.
                </p>

              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="text-center safety-box-safety rounded p-4 mt-4">

              <div className="mt-4">
                <h5 className="mb-0 whiteText">
                  <span className="safetyIconStyle-safety">
                    <FontAwesomeIcon icon={faTowerBroadcast} aria-label="About" />
                  </span>
                  {' '}
                  SOS Button
                </h5>
                <div className="services-title-border" />
                <p className="text-muted mt-3">
                  With just one click of the SOS button, our app
                  instantly connects you to the nearest police station
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="text-center safety-box-safety rounded p-4 mt-4">

              <div className="mt-4">
                <h5 className="mb-0 whiteText">
                  <span className="safetyIconStyle-safety">
                    <FontAwesomeIcon icon={faSquareH} aria-label="About" />
                  </span>
                  {' '}
                  Emegency Button
                </h5>
                <div className="services-title-border" />
                <p className="text-muted mt-3">
                  With a single press of the emergency button,
                  our app swiftly connects you to the nearest hospitals.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="text-center safety-box-safety rounded p-4 mt-4">

              <div className="mt-4">
                <h5 className="mb-0 whiteText">
                  <span className="safetyIconStyle-safety">
                    <FontAwesomeIcon icon={faBell} aria-label="About" />
                  </span>
                  Instant Notification
                </h5>
                <div className="services-title-border" />
                <p className="text-muted mt-3">
                  We receive notifications about your situation,
                  enabling us to offer immediate assistance and the necessary support.
                </p>

              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Safety;
