import React, { createContext, useState, useMemo } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

// Create a context for handling logout state and functions.
const LogoutContext = createContext();

/**
 * A provider component that wraps its children with the logout context.
 * It manages the logout state and provides functions to change the logout status.
 *
 * @param {object} children - The child components to be wrapped with the logout context.
 * @returns {JSX.Element} A JSX element representing the context provider.
 */
function Provider({ children }) {
  // Initialize logout state using local storage or default to false.
  const [logout, setLogout] = useState(() => {
    const storedLogout = localStorage.getItem('logout');
    return storedLogout === 'true'; // Convert string to boolean
  });

  // Create an object with logout state and changeLogout function.
  // Memoize the context value
  const valueToShare = useMemo(() => ({
    logout,
    changeLogout: (isLoggedIn) => {
      setLogout(isLoggedIn);
      localStorage.setItem('logout', isLoggedIn);
    },
  }), [logout]);

  return (
    <LogoutContext.Provider value={valueToShare}>
      {children}
    </LogoutContext.Provider>
  );
}

export { Provider };
export default LogoutContext;

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};
