const messages = {
  'en-US': {
    home: 'Home',
    whatIsYour: 'Where is your',
    destination: 'destination',
    connectYou: 'We will connect you with the',
    nearestDriver: 'nearest driver',
    rideResponsibility: 'Your Ride, Our Responsibility',
    fastConvenient: 'Fast, Convenient & Near By',
    affordableRates: 'Affordable Rates & Reliable Rides',
    bothWorlds: 'The Best of Both Worlds',
    OurVisionIs: ' Our Vision is',
    toDelever: 'To deliver eofyta to you',
    efoyyLab: 'Efoyy Lab',
    introducing: 'Introducing',
    homeSlogon: 'Crafted with the essence of human-centric design thinking!',
    whatIsEfoyy: 'efoyy vividly captures the emotional shift from tension to tranquility, resonating with the deeply satisfying feeling of letting go of burdens and embracing serenity.',
    requestRide: 'request a ride',
    signUpToDrive: 'Sign Up To Drive',
    company: 'Company',
    safety: 'Safety',
    features: 'Features',
    business: 'Business',
    driver: 'Driver',
    rider: 'Rider',
    tech: 'Tech',
    blog: 'Blog',
    booking: 'Booking',
    contact: 'Contact',
    language: 'Language',
    login: 'Login',
    logout: 'Logout',
    howTos: 'How Tos',
    lostAndFound: 'Lost & Found',
    driverPerks: 'Driver Perks',
    lottery: 'Lottery',
    blogs: 'blogs',
    security: 'Security',
    signupMessage: 'Thank you for your interest! To sign up, please download our mobile app and complete the registration process there.',
    downloadApp: 'Download the efoyy app',
    vision: 'To deliver eofyyta to our clients.',
    mission: ' Transcending boundaries in technology and innovation, we are committed to redefining mobility in Ethiopia.',
    values: 'We live these values: Safety, convenience, reliability, innovation,and Client-centric.',
    team: `Our experienced team at Efoyy, with over 60 years combined expertise, 
    crafts innovative, scalable, secure software, 
    transforming challenges into user-friendly, high-performance technological solutions`,
    team2: 'Continually learning and driven by our enthusiasm for the next cutting technology, we are dedicated to maintaining our position at the forefront of innovation.',
    careers: 'Careers',
    driverPolicy: 'Driver Privacy Policy',
    riderPolicy: 'Rider Privacy Policy',
    privacyPolicy: {
      titleDriver: 'Driver\'s Privacy Policy',
      titleRider: 'Rider\'s Privacy Policy',
      effectiveDate: 'Effective Date: March 01, 2024',
      customerPrivacyNotice: 'Customer Privacy Notice',
      customerPrivacyNoticeContent: `Welcome to Efoyy Transport Technology Solutions (ETTS), where safeguarding your privacy
      and personal information while using our Website, Android, and iOS apps
      is our utmost priority. Our commitment to transparency is reflected in this Customer Privacy Notice, providing
      you with a comprehensive understanding of how we handle, collect, utilize, share,
      and secure your data when utilizing our platform.`,
      tableOfContents: 'Table of Contents',
      informationWeCollect: ' Information We Collect',
      informationWeCollectContentP: `We collect various types of information to enhance your
      experience on our platform. This may include:`,
      informationWeCollectContent1: 'Personal information such as your name, email address, and phone number.',
      informationWeCollectContent2: 'Location data for providing our services, navigation, and safety.',
      informationWeCollectContent3: 'Payment details for secure transactions within the app.',
      informationWeCollectContent4: 'Ride history, timestamps, and payment records.',
      informationWeCollectContent5: 'Communications with our customer support and other users.',
      informationWeCollectContent6: `During certain rides, drivers may request to take your picture for safety purposes.
                                     These photos are securely stored on our servers and automatically 
                                     deleted after 24 hours unless suspicious activity is reported.`,
      informationWeCollectContent7: `We may collect additional data such as a picture of your driver's license,
                                      TIN certificate, a picture of your car, business number, birthday,
                                      business certification number, and car inspection certification
                                      to ensure compliance with our standards.`,
      howWeUseYourInformation: ' How We Use Your Information',
      howWeUseYourInformationContent1: ' Provide you with efficient and reliable services on our platform.',
      howWeUseYourInformationContent2: ' Process payments securely within the app.',
      howWeUseYourInformationContent3: ' Continuously improve your overall platform experience.',
      howWeUseYourInformationContent4: ' Respond promptly to inquiries and resolve issues through our customer support.',
      howWeUseYourInformationContent5: ' Verify identity and investigate policy violations to enhance safety.',
      howWeUseYourInformationContent6: ' Share personalized promotions and updates with your consent.',
      howWeUseYourInformationContent7: ' Comply with all applicable laws and regulations.',
      sharingYourInformation: ' Sharing Your Information',
      sharingYourInformationContentP: `Rest assured, we take your privacy seriously
      and share your information only in specific situations:`,
      sharingYourInformationContent1: ' With other users when you request a ride or use our services.',
      sharingYourInformationContent2: 'With law enforcement or authorities as required by applicable laws.',
      sharingYourInformationContent3: `With trusted third-party service providers
        who assist us in delivering our services.`,
      sharingYourInformationContent4: 'With your explicit consent for marketing purposes.',
      yourRightsAndChoices: ' Your Rights and Choices',
      yourRightsAndChoicesContent1: 'Access and correction of your personal data.',
      yourRightsAndChoicesContent2: 'Request account deletion, subject to legal retention requirements.',
      yourRightsAndChoicesContent3: 'Manage your marketing preferences and opt-out as you see fit.',
      yourRightsAndChoicesContent4: 'Control location data sharing through your device settings.',
      dataProtectionMeasures: ' Data Protection Measures',
      dataProtectionMeasuresContent: `Our commitment to your privacy includes robust security measures.
      We employ state-of-the-art practices to protect your
      data from unauthorized access, disclosure, alteration,
      or destruction. Our security protocols encompass encryption
      and other advanced security measures. For more details, please refer to our
      Security Policy.`,
      reportingSecurityConcerns: ' Reporting Suspected Illegal Use and Security Concerns',
      reportingSecurityConcernsContent: `If you suspect illegal app use or have security concerns,
      contact us at support@efoyy.com,
      or using the contact us page at efoyy.com
      or the feedback form on the app. Your cooperation helps maintain app security.`,
      cookiesService: ' Cookies Service',
      cookiesServiceContent: `We use cookies and similar technologies to enhance
      your browsing experience and collect information about your interactions with our app.`,
      dataCollectionLogs: ' Data Collection Logs',
      dataCollectionLogsContent: `We may collect data logs for the purpose of improving our services
      and enhancing user experiences.
      These logs may include information such as device information,
      app usage statistics, and user interactions within the app.`,
      childrensPrivacy: ' Children’s Privacy',
      childrensPrivacyContent: `Efoyy is committed to protecting the privacy of children.
      Our app is not designed for, nor do we knowingly collect data from
      children under the age of 13. In compliance with the Children’s
      Online Privacy Protection Act (COPPA) and GDPR regulations for
      minors in the EU, we do not collect or store any personal information
      from children under the specified age limits.`,
      security: ' Security',
      securityContent: `We protect data from unauthorized access, disclosure, alteration, or destruction
      using encryption and security measures. For more information about our security
      practices, please refer to our Security Policy.`,
      disputeResolution: ' Dispute Resolution',
      disputeResolutionContent: `In the event of any dispute related to privacy concerns or data handling,
      users are encouraged to contact us directly
      at support@efoyy.com for resolution.
      We are committed to resolving disputes amicably and efficiently. If a satisfactory
      resolution is not reached, users have the right to contact
      relevant data protection authorities.`,
      changesToPrivacyNotice: ' Changes to This Privacy Notice',
      changesToPrivacyNoticeContent: `To ensure your ongoing transparency, we may update this Customer
      Privacy Notice to align with operational, legal, or regulatory changes.
      Any updates will be promptly communicated within the app, and the "Last
      Updated" date will indicate the latest revision.`,
      contactUs: ' Contact Us',
      contactUsContent: `If you have any questions or concerns about this Privacy Notice or
      your platform usage, please don't hesitate to reach out to us at support@efoyy.com.
      Thank you for choosing Efoyy Transport Technology Solutions (ETTS).`,
      thankYou: 'Thank you for choosing Efoyy Transport Technology Solutions (ETTS).',
    },
    basic: 'Basics',
    essential: 'Essentials',
    pro: 'Pro',
    uniqueValue: 'Unique Values',
    gamification: 'Gamification',
    multiplePricingModels: 'Multiple Pricing Models',
    aiComingSoon: 'AI - Coming Soon',
    telegramBookingComingSoon: 'Telegram Booking - Coming Soon',
    efoytaTime: '24 efoyta time - Provide service with zero credit',
    customization: 'Customization',
    lightAndDarkMode: 'Light and Dark Mode',
    dynamicFuelPriceAdjustment: 'Dynamic Fuel Price Adjustment',
    multiplePaymentGatewayIntegration: 'Multiple Payment Gateway Integration',
    weeklyLotteryDraw: 'Weekly Lottery Draw - Learn More',
    nearestPoliceStation: 'Police Station Proximity Detection',
    nearestHealthFacilities: 'Medical Facility Proximity Detection',
    sos: 'SOS - Assistance button for emergencies',
    easyRegistration: 'Easy Registration',
    accurateNearestDriverFinder: 'Accurate Nearest Driver Finder',
    requestFemaleOnlyDriver: 'Request Female Only Driver',
    requestWheelchairChildSeat: 'Request Wheelchair and Child Seat ',
    languageSupport: 'Amharic, Oromigna, Tigrigna Support',
    techInnovation: 'Tech & Innovation',
    scalable: 'Scalable Infrastructure',
    modernAPI: 'Modern API Design',
    securityApproved: 'INSA Approved Security',
    cloudIntegration: 'Cloud Integration',
    ciCdAutomation: 'CI/CD Automation',
    aaaRatingEffort: 'Working Towards AAA Rating',
    robustDesign: 'Robust Design',

  },
  es: {
    home: 'ቤት',
    efoyyLab: 'Efoyy Lab',
    introducing: 'ማስተዋወቅ',
    homeSlogon: 'Crafted with the essence of human-centric design thinking -s!',
    requestRide: 'request a ride-s',
    signUpToDrive: 'Sign Up To Drive -s',
    company: 'Company-s',
    safety: 'Safety',
    features: 'Features-s',
    business: 'Business-s',
    driver: 'Driver-s',
    rider: 'Rider-s',
    tech: 'Tech-s',
    blog: 'Blog-s',
    booking: 'Booking',
    contact: 'Contact-s',
    language: 'Language-s',
    login: 'Login-s',
    logout: 'Logout',
    signupMessage: 'ለፍላጎትዎ እናመሰግናለን! ለመመዝገብ እባክዎ የእኛን ያውርዱ የሞባይል መተግበሪያ እና የምዝገባ ሂደቱን እዚያ ያጠናቅቁ::',
    downloadApp: 'Download the efoyy app',
    vision: 'To deliver eofyyta to our clients.',
    mission: ' Transcending boundaries in technology and innovation, we are committed to redefining mobility in Ethiopia.',
    values: 'We live these values: Safety, convenience, reliability, innovation,and Client-centric.',
    team: `With a 60+ years of combined experience in software and DevOps, 
          our team goes beyond just participating - we're committed to elevating the 
          standards of innovation and technology. Focused on excellence, we are 
          meticulously crafting a world-class platform with a state-of-the-art tech stack, infrastructure
          designed for scalability, security, user-friendliness, and robust performance, 
          thus redefining technological benchmarks -  Continually learning and fueled by excitement for cutting-edge technology, we are committed to staying at the forefront of innovation.`,
    careers: 'Careers',
  },
  // Add more language messages as needed
};

export default messages;
