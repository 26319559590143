import React, { createContext, useState, useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

// Create a context to manage authentication state
const AuthContext = createContext();

// Define a provider component to wrap your app and provide authentication context
function AuthProvider({ children }) {
  // Use the useState hook to manage the isAuthenticated state
//  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const auth = localStorage.getItem('auth');
    return auth === 'true'; // Convert string to boolean
  });

  // Define a function to update the isAuthenticated state
  const auth = (isLoggedIn) => {
    setIsAuthenticated(isLoggedIn);
    localStorage.setItem('auth', isLoggedIn);
  };

  // Memoize the context value
  const contextValue = useMemo(() => ({ isAuthenticated, auth }), [isAuthenticated]);

  // Render the AuthContext.Provider with the context value
  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Export the AuthProvider and AuthContext
export { AuthProvider };
export default AuthContext;
