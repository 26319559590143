/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useForm } from 'react-hook-form';

function Register() {
  const {
    register, handleSubmit, watch, formState: { errors },
  } = useForm();

  const onSubmit = async (registerData) => {
    // Handle registration logic here
    console.log(registerData);
    // You might want to send registerData to your backend
  };

  // Custom validation for confirm password
  const validatePassword = (value) => value === watch('password') || 'Passwords don\'t match';

  return (
    <div className="register -container">
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card register-box">
              <div className="card-header">
                <h4>Register</h4>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label register-label-name">First Name</label>
                    <input
                      style={{ color: '#D3D3D3', backgroundColor: '#26282b', border: '#26282b' }}
                      type="text"
                      className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                      id="firstName"
                      {...register('firstName', { required: 'First name is required' })}
                    />
                    {errors.firstName && <div className="invalid-feedback">{errors.firstName.message}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="lastName" className="form-label register-label-name">Last Name</label>
                    <input
                      style={{ color: '#D3D3D3', backgroundColor: '#26282b', border: '#26282b' }}
                      type="text"
                      className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                      id="lastName"
                      {...register('lastName', { required: 'Last name is required' })}
                    />
                    {errors.lastName && <div className="invalid-feedback">{errors.lastName.message}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="gender" className="form-label register-label-name">Gender</label>
                    <select
                      style={{ color: '#D3D3D3', backgroundColor: '#26282b', border: '#26282b' }}
                      className={`form-control ${errors.gender ? 'is-invalid' : ''}`}
                      id="gender"
                      {...register('gender', { required: 'Gender is required' })}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {errors.gender && <div className="invalid-feedback">{errors.gender.message}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label register-label-name">Phone Number</label>
                    <input
                      style={{ color: '#D3D3D3', backgroundColor: '#26282b', border: '#26282b' }}
                      type="tel"
                      className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                      id="phone"
                      {...register('phone', { required: 'Phone number is required' })}
                    />
                    {errors.phone && <div className="invalid-feedback">{errors.phone.message}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label register-label-name">Password</label>
                    <input
                      style={{ color: '#D3D3D3', backgroundColor: '#26282b', border: '#26282b' }}
                      type="password"
                      className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                      id="password"
                      {...register('password', { required: 'Password is required', minLength: { value: 6, message: 'Password must be at least 6 characters' } })}
                    />
                    {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label register-label-name">Confirm Password</label>
                    <input
                      style={{ color: '#D3D3D3', backgroundColor: '#26282b', border: '#26282b' }}
                      type="password"
                      className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                      id="confirmPassword"
                      {...register('confirmPassword', { validate: validatePassword })}
                    />
                    {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword.message}</div>}
                  </div>
                  <button type="submit" className="btn btn-custom">Register</button>
                </form>
                <div className="text-center mt-4 login-link">
                  <a href="/login">Login</a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
