import React from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'react-bootstrap';

import useLanguageContext from '../hooks/useLanguageContext';
import messages from '../messages';

function PlayStoreAppStoreIcons() {
  // eslint-disable-next-line no-unused-vars
  const { lang, changeLang } = useLanguageContext();
  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <Container className="mt-4 pt-2 text-center">
        <p className="whiteText">
          {' '}
          <FormattedMessage id="downloadApp" />
        </p>
        <Row className="justify-content-center">
          <Col xs="auto">
            <a href="#android">
              <img
                src="images/googlePlayBadge.svg"
                className="img-fluid rounded"
                alt="Google Play Store"
                width="120px"
                height="100px"
              />
            </a>
          </Col>
          <Col xs="auto">
            <a href="#android">
              <img
                src="images/appstore.svg"
                className="img-fluid rounded"
                alt="Apple App Store"
                width="120px"
                height="100px"
              />
            </a>
          </Col>
        </Row>
      </Container>
    </IntlProvider>
  );
}

export default PlayStoreAppStoreIcons;
