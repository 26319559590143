import { useContext } from 'react';
import LogoutContext from '../context/LogoutContext';

/**
 * A custom hook to access the logout context values and functions.
 * It uses the useContext hook to retrieve the logout context.
 *
 * @returns {object} An object containing logout context values and functions.
 */
function useLogoutContext() {
  return useContext(LogoutContext);
}

export default useLogoutContext;
