// useVerifyPhoneNumberHook.js
import { useLazyQuery } from '@apollo/client';
import { VERIFY_PHONE_NUMBER } from '../queries/auth';

const useVerifyPhoneNumberHook = () => {
  const [verifyPhoneNumber, { data, loading, error }] = useLazyQuery(VERIFY_PHONE_NUMBER, {
    onCompleted: (d) => {
      console.log(d);
      // Additional actions upon completion
    },
    onError: (e) => console.log(e),
  });

  return [verifyPhoneNumber, { data, loading, error }];
};

export default useVerifyPhoneNumberHook;
