// graphql/queries.js
import { gql } from '@apollo/client';

const VERIFY_PHONE_NUMBER = gql`
  query VerifyPhoneNumber($userInput: VerifyPhoneNumberInputType) {
    verifyPhoneNumber(userInput: $userInput) {
      status
      errorMessage
    }
  }
`;
const VERIFY_PHONE_FAKE = gql`
  query VerifyPhoneNumber($userInput: VerifyPhoneNumberInputType) {
    verifyPhoneNumber(userInput: $userInput) {
      statusCode
    }
  }
`;

export { VERIFY_PHONE_NUMBER, VERIFY_PHONE_FAKE };
