import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { Navbar, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import NavLinks from './NavLinks';
import UserMenu from './UserMenu';
// import LanguageSelector from '../../LanguageSelector';
import messages from '../../../messages';
import useLanguageContext from '../../../hooks/useLanguageContext';
import useLogoutContext from '../../../hooks/useLogoutContext';
import useAuthContext from '../../../hooks/useAuthContext';

function Header() {
  const navigate = useNavigate();
  const { lang } = useLanguageContext();
  // eslint-disable-next-line no-unused-vars
  const { logout, changeLogout } = useLogoutContext();
  // eslint-disable-next-line no-unused-vars
  const { auth } = useAuthContext();
  const [activeNavItem, setActiveNavItem] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Define the functions for user menu and other actions
  const redirectToProfilePage = () => navigate('/show-user-profile');
  const handleLogout = async () => { /* ... logout logic ... */ };
  const handleSwitchToAdmin = () => { /* ... switch to admin logic ... */ };
  const getUserInitials = () => 'User Initials'; // Replace with actual initials logic

  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <div className="container">
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top" className={`${isScrolled ? 'opaque-header' : ''} custom-navbar-padding`}>
          <Navbar.Brand className="text-custom logo-plain" href="#home">
            <img src="images/Coral_Light-Gray.png" alt="Logo" style={{ height: '25px' }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <NavLinks setActiveNavItem={setActiveNavItem} activeNavItem={activeNavItem} />
              {/* <LanguageSelector /> */}
              <Nav.Item>
                <UserMenu
                  logout={logout}
                  redirectToProfilePage={redirectToProfilePage}
                  handleLogout={handleLogout}
                  handleSwitchToAdmin={handleSwitchToAdmin}
                  getUserInitials={getUserInitials}
                />
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </IntlProvider>
  );
}

export default Header;
