import React from 'react';

import Home from './Home';
import Company from './Company';
import Safety from './Safety';
import Driver from './Driver';
import Rider from './Rider';
import Features from './Features';
import Contact from './Contact';
// import Business from './Business';

// import Blog from './Blog';

// import Footter from './Footter';

function Layout() {
  return (
    <div>
      <Home />
      <Company />
      <Safety />
      <Driver />
      <Rider />
      <Features />
      <Contact />
      {/*
      <Business />

      <Blog />
       */}
    </div>
  );
}

export default Layout;
