import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function SplashScreen({ onLoaded }) {
  const videoRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1.5; // Change this value to your desired playback speed
    }
    // Here, you would wait for the video to end, or set a timeout for the duration of the video
    const timer = setTimeout(() => {
      onLoaded();
    }, 5000); // Assuming your video is 5 seconds long

    return () => clearTimeout(timer);
  }, [onLoaded]);

  return (
    <div style={{
      position: 'fixed', // Changed from 'absolute' to 'fixed' to ensure it covers the entire screen
      width: '100vw', // Ensure it covers full viewport width
      height: '100vh', // Ensure it covers full viewport height
      top: 0,
      left: 0,
      zIndex: 100,
      overflow: 'hidden', // Add this to prevent any scrolling caused by the video
    }}
    >
      {isMobile ? (
        <video autoPlay muted playsInline onEnded={onLoaded} style={{ width: '100%', height: '100%', objectFit: 'cover' }} ref={videoRef}>
          <source src="videos/logo_aniamtion_mobile.mp4" type="video/mp4" />
          <source src="movie.ogg" type="video/ogg" />
          <track src="captions_en.vtt" kind="captions" srcLang="en" label="English" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video autoPlay muted playsInline onEnded={onLoaded} style={{ width: '100%', height: '100%', objectFit: 'cover' }} ref={videoRef}>
          <source src="videos/logo_aniamtion.mp4" type="video/mp4" />
          <source src="movie.ogg" type="video/ogg" />
          <track src="captions_en.vtt" kind="captions" srcLang="en" label="English" />
          Your browser does not support the video tag.
        </video>
      )}

    </div>
  );
}

SplashScreen.propTypes = {
  onLoaded: PropTypes.func.isRequired,
};

export default SplashScreen;
