import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import messages from '../../messages'; // Import your translation messages here

function RiderPrivacyPolicy() {
  const locale = 'en-US'; // Set your desired locale here

  const {
    privacyPolicy: {
      titleRider,
      effectiveDate,
      customerPrivacyNotice,
      customerPrivacyNoticeContent,
      tableOfContents,
      informationWeCollect,
      informationWeCollectContentP,
      informationWeCollectContent1,
      informationWeCollectContent2,
      informationWeCollectContent3,
      informationWeCollectContent4,
      informationWeCollectContent5,
      informationWeCollectContent6,
      howWeUseYourInformation,
      howWeUseYourInformationContent1,
      howWeUseYourInformationContent2,
      howWeUseYourInformationContent3,
      howWeUseYourInformationContent4,
      howWeUseYourInformationContent5,
      howWeUseYourInformationContent6,
      howWeUseYourInformationContent7,
      sharingYourInformation,
      sharingYourInformationContentP,
      sharingYourInformationContent1,
      sharingYourInformationContent2,
      sharingYourInformationContent3,
      sharingYourInformationContent4,
      yourRightsAndChoices,
      yourRightsAndChoicesContent1,
      yourRightsAndChoicesContent2,
      yourRightsAndChoicesContent3,
      yourRightsAndChoicesContent4,
      dataProtectionMeasures,
      dataProtectionMeasuresContent,
      reportingSecurityConcerns,
      reportingSecurityConcernsContent,
      cookiesService,
      cookiesServiceContent,
      dataCollectionLogs,
      dataCollectionLogsContent,
      childrensPrivacy,
      childrensPrivacyContent,
      security,
      securityContent,
      disputeResolution,
      disputeResolutionContent,
      changesToPrivacyNotice,
      changesToPrivacyNoticeContent,
      contactUs,
      contactUsContent,
      thankYou,
    },
  } = messages[locale];

  return (
    <section className="privacy-policy-container">
      <a className="privacy-link-tag" href="/">
        <FontAwesomeIcon icon={faChevronLeft} aria-label="Back" />
        {' '}
        Back
      </a>
      <h1>{titleRider}</h1>
      <p>{effectiveDate}</p>

      <h2>{customerPrivacyNotice}</h2>
      <p>{customerPrivacyNoticeContent}</p>

      <div className="table-of-contents">
        <h2>{tableOfContents}</h2>
        <ol>
          <li>
            <a href="#section-1">{informationWeCollect}</a>
          </li>
          <li>
            <a href="#section-2">{howWeUseYourInformation}</a>
          </li>
          <li>
            <a href="#section-3">{sharingYourInformation}</a>
          </li>
          <li>
            <a href="#section-4">{yourRightsAndChoices}</a>
          </li>
          <li>
            <a href="#section-5">{dataProtectionMeasures}</a>
          </li>
          <li>
            <a href="#section-6">{reportingSecurityConcerns}</a>
          </li>
          <li>
            <a href="#section-7">{cookiesService}</a>
          </li>
          <li>
            <a href="#section-8">{dataCollectionLogs}</a>
          </li>
          <li>
            <a href="#section-9">{childrensPrivacy}</a>
          </li>
          <li>
            <a href="#section-10">{security}</a>
          </li>
          <li>
            <a href="#section-11">{disputeResolution}</a>
          </li>
          <li>
            <a href="#section-12">{changesToPrivacyNotice}</a>
          </li>
          <li>
            <a href="#section-13">{contactUs}</a>
          </li>
        </ol>
      </div>
      <section id="section-1" className="privacy-policy-section">
        <h2>
          1.
          {informationWeCollect}
        </h2>
        <p>{informationWeCollectContentP}</p>
        <ul>
          <li>{informationWeCollectContent1}</li>
          <li>{informationWeCollectContent2}</li>
          <li>{informationWeCollectContent3}</li>
          <li>{informationWeCollectContent4}</li>
          <li>{informationWeCollectContent5}</li>
          <li>{informationWeCollectContent6}</li>

        </ul>
      </section>

      <section id="section-2" className="privacy-policy-section">
        <h2>
          2.
          {howWeUseYourInformation}
        </h2>
        <ul>
          <li>{howWeUseYourInformationContent1}</li>
          <li>{howWeUseYourInformationContent2}</li>
          <li>{howWeUseYourInformationContent3}</li>
          <li>{howWeUseYourInformationContent4}</li>
          <li>{howWeUseYourInformationContent5}</li>
          <li>{howWeUseYourInformationContent6}</li>
          <li>{howWeUseYourInformationContent7}</li>
        </ul>

      </section>

      <section id="section-3" className="privacy-policy-section">
        <h2>
          3.
          {sharingYourInformation}
        </h2>
        <p>{sharingYourInformationContentP}</p>
        <ul>
          <li>{sharingYourInformationContent1}</li>
          <li>{sharingYourInformationContent2}</li>
          <li>{sharingYourInformationContent3}</li>
          <li>{sharingYourInformationContent4}</li>
        </ul>
      </section>

      <section id="section-4" className="privacy-policy-section">
        <h2>
          4.
          {yourRightsAndChoices}
        </h2>
        <ul>
          <li>{yourRightsAndChoicesContent1}</li>
          <li>{yourRightsAndChoicesContent2}</li>
          <li>{yourRightsAndChoicesContent3}</li>
          <li>{yourRightsAndChoicesContent4}</li>
        </ul>

      </section>

      <section id="section-5" className="privacy-policy-section">
        <h2>
          5.
          {dataProtectionMeasures}
        </h2>
        <p>{dataProtectionMeasuresContent}</p>
      </section>

      <section id="section-6" className="privacy-policy-section">
        <h2>
          6.
          {reportingSecurityConcerns}
        </h2>
        <p>{reportingSecurityConcernsContent}</p>
      </section>

      <section id="section-7" className="privacy-policy-section">
        <h2>
          7.
          {cookiesService}
        </h2>
        <p>{cookiesServiceContent}</p>
      </section>

      <section id="section-8" className="privacy-policy-section">
        <h2>
          8.
          {dataCollectionLogs}
        </h2>
        <p>{dataCollectionLogsContent}</p>
      </section>

      <section id="section-9" className="privacy-policy-section">
        <h2>
          9.
          {childrensPrivacy}
        </h2>
        <p>{childrensPrivacyContent}</p>
      </section>

      <section id="section-10" className="privacy-policy-section">
        <h2>
          10.
          {security}
        </h2>
        <p>{securityContent}</p>
      </section>

      <section id="section-11" className="privacy-policy-section">
        <h2>
          11.
          {disputeResolution}
        </h2>
        <p>{disputeResolutionContent}</p>
      </section>

      <section id="section-12" className="privacy-policy-section">
        <h2>
          12.
          {changesToPrivacyNotice}
        </h2>
        <p>{changesToPrivacyNoticeContent}</p>
      </section>

      <section id="section-13" className="privacy-policy-section">
        <h2>
          13.
          {contactUs}
        </h2>
        <p>{contactUsContent}</p>
        <p>{thankYou}</p>
      </section>
    </section>
  );
}

export default RiderPrivacyPolicy;
