import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'; // Import BrowserRouter, Routes, and Route

import { Provider as LanguageSelectorProvider } from '../context/LanguageSelectorContext';
import { Provider as LogoutProvider } from '../context/LogoutContext';
import Layout from './frontPage/Layout';
import BasicLayout from './frontPage/BasicLayout';
import MainLayout from './frontPage/MainLayout';
import Login from './Login';
import Register from './Register';
// import Header from './SOLID/header/Header';
import CreateUserProfile from './CreateUserProfile';
import UserProfile from './UserProfile';

import DriverPrivacyPolicy from './frontPage/DriverPrivacyPolicy';
import RiderPrivacyPolicy from './frontPage/RiderPrivacyPolicy';

import useAuthContext from '../hooks/useAuthContext';

import SplashScreen from './frontPage/SplashScreen';

function App() {
  // Get the isAuthenticated value from the authentication context
  const { isAuthenticated } = useAuthContext();

  const [isSplash, setIsSplash] = useState(window.location.pathname === '/');

  useEffect(() => {
    if (window.location.pathname !== '/') {
      setIsSplash(false); // Disable splash screen for non-root URLs
    }
  }, []);

  const handleSplash = () => setIsSplash(false);
  return (
    <LanguageSelectorProvider>
      <LogoutProvider>
        <Router>
          {isSplash ? (
            <SplashScreen onLoaded={handleSplash} />
          ) : (
            <Routes>
              <Route
                path="/"
                element={(
                  <MainLayout>
                    <Layout />
                  </MainLayout>
              )}
              />

              <Route path="/driver/privacy-policy" element={<BasicLayout><DriverPrivacyPolicy /></BasicLayout>} />
              <Route path="/" element={<Layout />} />
              <Route path="/login" element={<MainLayout><Login /></MainLayout>} />
              <Route path="/register" element={<MainLayout><Register /></MainLayout>} />
              <Route path="/driver-privacy-policy" element={<DriverPrivacyPolicy />} />
              <Route
                path="/create-user-profile"
                element={
                isAuthenticated ? (
                  <CreateUserProfile />
                ) : (
                  <Navigate to="/login" />
                )
              }
              />

              <Route
                path="/show-user-profile"
                element={
                isAuthenticated ? <UserProfile /> : <Navigate to="/login " />
              }
              />

              <Route
                path="/driver/privacy-policy"
                element={(
                  <BasicLayout>
                    <DriverPrivacyPolicy />
                  </BasicLayout>
              )}
              />
              <Route
                path="/rider/privacy-policy"
                element={(
                  <BasicLayout>
                    <RiderPrivacyPolicy />
                  </BasicLayout>
              )}
              />

            </Routes>
          )}
        </Router>
      </LogoutProvider>
    </LanguageSelectorProvider>
  );
}

export default App;
// https://www.youtube.com/watch?v=wYpCWwD1oz0
