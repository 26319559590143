/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import SocialConnection from './SocialConnection';

import useLanguageContext from '../../hooks/useLanguageContext';

import messages from '../../messages';

function Footer() {
  const currentYear = new Date().getFullYear();

  // eslint-disable-next-line no-unused-vars
  const { lang, changeLang } = useLanguageContext();
  return (
    <IntlProvider locale={lang} messages={messages[lang]}>

      <section className="section footer ">
        <div className="container">
          <div className="row">
            {/* Vertical Menu Start */}
            <div className="col-12 col-sm-6 col-md-4 mb-4 mb-md-0 footer-link">
              <ul className="list-unstyled">
                <li><a href="#home"><FormattedMessage id="home" /></a></li>
                <li><a href="#company"><FormattedMessage id="company" /></a></li>
                <li><a href="#"><FormattedMessage id="careers" /></a></li>
                <li><a href="#safety"><FormattedMessage id="safety" /></a></li>
                <li><a href="#features"><FormattedMessage id="features" /></a></li>
              </ul>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-4 mb-md-0 footer-link">
              <ul className="list-unstyled">
                <li><a href="#driver"><FormattedMessage id="driver" /></a></li>
                <li><a href="#rider"><FormattedMessage id="rider" /></a></li>
                <li><a href="#how-tos"><FormattedMessage id="howTos" /></a></li>
                <li><a href="#lost-founds"><FormattedMessage id="lostAndFound" /></a></li>
              </ul>
            </div>
            <div className="col-12 col-sm-6 col-md-4 footer-link">
              <ul className="list-unstyled">
                <li><a href="#driver-perks"><FormattedMessage id="driverPerks" /></a></li>
                {/* <li><a href="#lottery"><FormattedMessage id="lottery" /></a></li>
                <li><a href="#gamification"><FormattedMessage id="gamification" /></a></li> */}
                <li><a href="#blogs"><FormattedMessage id="blog" /></a></li>
                <li><a href="#security"><FormattedMessage id="security" /></a></li>
              </ul>
            </div>
            {/* Vertical Menu End */}
            <div className="col-12">
              <div className="text-center text-white  footer-alt">
                <SocialConnection />
                <p className="copyright_content mb-0 mt-3">
                  {currentYear}
                  {' '}
                  &copy;
                  {' '}
                  <span className="text-custom fw-bold">Efoyy Transport Technology Solutions PLC (ETTS)</span>
                  .
                </p>

                <div className="footer-link-privacy">
                  <a href="/driver/privacy-policy" className="text-custom fw-bold">
                    {' '}
                    <FormattedMessage id="driverPolicy" />
                  </a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a href="/rider/privacy-policy" className="text-custom fw-bold">Rider Privacy Policy</a>
                  <FormattedMessage id="riderPolicy" />
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

    </IntlProvider>
  );
}

export default Footer;
