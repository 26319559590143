import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function UserMenu({
  logout, redirectToProfilePage, handleLogout, handleSwitchToAdmin, getUserInitials,
}) {
  if (logout) {
    return (
      <NavDropdown title={getUserInitials()} className="nav-item">
        <NavDropdown.Item>
          <a className="nav-link" href="/#" onClick={redirectToProfilePage}>
            Profile
          </a>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <a className="nav-link" href="/#" onClick={handleLogout}>
            Logout
          </a>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <a className="nav-link" href="/#" onClick={handleSwitchToAdmin}>
            Admin
          </a>
        </NavDropdown.Item>
      </NavDropdown>
    );
  }
  return (
    <Link className="nav-link header-nav-item-font-color" to="/login">
      Login
    </Link>

  );
}

UserMenu.propTypes = {
  logout: PropTypes.bool.isRequired,
  redirectToProfilePage: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  handleSwitchToAdmin: PropTypes.func.isRequired,
  getUserInitials: PropTypes.func.isRequired,
};

export default UserMenu;
