/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Navigation, Pagination, A11y, Autoplay,
} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import necessary Swiper styles
import 'swiper/css'; // Core Swiper
import 'swiper/css/navigation'; // Navigation
import 'swiper/css/pagination'; // Pagination
import 'swiper/css/scrollbar'; // Scrollbar
import 'swiper/css/effect-cube'; // Cube Effect

import { IntlProvider, FormattedMessage } from 'react-intl';
import useLanguageContext from '../../hooks/useLanguageContext';
import messages from '../../messages';

import SocialConnection from './SocialConnection';
import PlayStoreAppStoreIcons from '../PlayStoreAppStoreIcons';

function Features() {
  const { lang } = useLanguageContext();

  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <section className=" section driverContainer" id="driver">

        <div className="container mb-5">
          <div className="row">
            <div className="col-lg-12">
              <div className="driverHeader text-center mb-5">
                <h2>
                  <span className=" fw-bold whiteText">

                    <FormattedMessage id="driver" />
                    <span className="beta-tag">Beta</span>
                  </span>
                </h2>
                <p className="text-muted mx-auto section-subtitle mt-3">
                  Join us today to experience a ride service app that values
                  safety, comfort, dedication, and hard work. Join the Efoyy
                  community now!
                </p>
              </div>
              <SocialConnection />
            </div>
            <div className="text-center mt-5">

              <h4 className="fw-bold whiteText">
                How to become

                <span className="whiteText"> efoyy&apos;s driver </span>
              </h4>
            </div>
            <div className="text-center mt-4">
              <button type="button" className="primary-button">
                Tutorial
              </button>
            </div>
          </div>
        </div>

        <Swiper
          modules={[Navigation, Pagination, A11y, Autoplay]}
          spaceBetween={0}
          slidesPerView={4}
          effect="fade"
          loop
          pagination={{
            clickable: true,

          }}
          scrollbar={{ draggable: true }}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: '.swiper-button-next-custom',
            prevEl: '.swiper-button-prev-custom',
          }}
        >

          <SwiperSlide>
            <div className="image-item text-center">
              <a href="http://efoyy.com">
                <img
                  src="images/cars/mini.png"
                  className="img-fluid mx-auto d-block rounded mr-3"
                  alt="Mini"
                  style={{ width: '50%', height: 'auto' }}
                />
                <p className="image-subtitle whiteText">Regular</p>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="image-item text-center">
              <a href="http://efoyy.com">
                <img
                  src="images/cars/micro.png"
                  alt="Mini"
                  style={{ width: '50%', height: 'auto' }}
                />
                <p className="image-subtitle whiteText">Standard</p>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="image-item text-center">
              <a href="http://efoyy.com">
                <img
                  src="images/cars/luxary.png"
                  className="img-fluid mx-auto d-block rounded mr-3"
                  alt="Mini"
                  style={{ width: '50%', height: 'auto' }}
                />
                <p className="image-subtitle whiteText">SUV</p>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="image-item text-center">
              <a href="http://efoyy.com">
                <img
                  src="images/cars/primum.png"
                  className="img-fluid mx-auto d-block rounded mr-3"
                  alt="Mini"
                  style={{ width: '50%', height: 'auto' }}
                />
                <p className="image-subtitle whiteText">Luxary</p>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="image-item text-center">
              <a href="http://efoyy.com">
                <img
                  src="images/cars/minbus.png"
                  className="img-fluid mx-auto d-block rounded mr-3"
                  alt="Mini"
                  style={{ width: '50%', height: 'auto' }}
                />
                <p className="image-subtitle whiteText">Minbus</p>
              </a>
            </div>
          </SwiperSlide>

          {/* Add more SwiperSlides as needed */}
        </Swiper>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <h4 className="fw-bold whiteText">
                  Are You Available To Drive?
                </h4>
              </div>
              <div className="text-center mt-4">
                <button type="button" className="primary-button">
                  Join Now
                </button>

              </div>
              <PlayStoreAppStoreIcons />
            </div>
          </div>
        </div>
      </section>
    </IntlProvider>
  );
}

export default Features;
