import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faXTwitter, faFacebookF, faInstagram, faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';

function SocialConnection() {
  return (
    <div className="row justify-content-center align-items-center social-icons-container">
      <div className="col-12 text-center">

        <a href="https://twitter.com" className="social-icon">
          <FontAwesomeIcon icon={faXTwitter} />
          {' '}
          {/* Change this line */}
        </a>
        <a href="https://www.facebook.com/profile.php?id=61554721444731" className="social-icon" aria-label="facebook">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a href="https://instagram.com" className="social-icon instagram-icon">
          {' '}
          {/* Add a class */}
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://linkedin.com" className="social-icon" aria-label="linkdin">
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
      </div>
    </div>
  );
}

export default SocialConnection;
