import React from 'react';
import { Nav } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line no-unused-vars, import/no-extraneous-dependencies
import PropTypes from 'prop-types';

// NavLinks.js
function NavLinks({ setActiveNavItem, activeNavItem }) {
  // Function to render a single nav link
  const renderNavLink = (labelId) => (
    <Nav.Link
      href={`/#${labelId}`}
      className={`${activeNavItem === labelId ? 'header-nav-item-active' : ''} header-nav-item-font-color`}
      onClick={() => setActiveNavItem(labelId)}
    >
      <FormattedMessage id={labelId} />
    </Nav.Link>
  );

  return (
    <>
      {renderNavLink('home')}
      {renderNavLink('company')}
      {renderNavLink('safety')}
      {renderNavLink('driver')}
      {renderNavLink('rider')}
      {renderNavLink('features')}
      {renderNavLink('contact')}
      {/*
      {renderNavLink('business')}
      {renderNavLink('driver')}
      {renderNavLink('rider')}
      {renderNavLink('efoyyLab')}
      {renderNavLink('blog')}
      {renderNavLink('booking')}
      {renderNavLink('contact')} */}
    </>
  );
}

export default NavLinks;

NavLinks.propTypes = {
  setActiveNavItem: PropTypes.func.isRequired,
  activeNavItem: PropTypes.string.isRequired,
};
