/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom/client';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AuthProvider } from './context/AuthContext';
import App from './components/App';
// https://www.apollographql.com/docs/react/networking/authentication/
const httpLink = createHttpLink({
  uri: 'http://localhost:5000/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const accessToken = localStorage.getItem('efoyy-accessToken');
  const refreshToken = localStorage.getItem('efoyy-refreshToken');

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
      'x-refresh-token': refreshToken ? `Bearer ${refreshToken}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    // Define type policies to customize caching behavior
    typePolicies: {
      Query: {
        fields: {
          // Create a custom field called 'allQueries' to match all queries
          allQueries: {
            maxAge: 600, // Cache for 1 hour
            // Define how to merge existing and incoming data
            merge(existing, incoming) {
              const now = Date.now();
              // Check if both existing and incoming data are available
              if (
                existing
                && existing.__typename === 'Query'
                && incoming
                && incoming.__typename === 'Query'
              ) {
                // Merge the data by combining 'allQueries' arrays
                // and updating the 'updatedAt' timestamp
                return {
                  ...incoming,
                  allQueries: [...existing.allQueries, ...incoming.allQueries],
                  updatedAt: now,
                };
              }
              return incoming;
            },
          },
        },
      },
    },
  }),
});

ReactGA.initialize('G-XKW72ZBR6B');
ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'efoyy -title' });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}>

    <AuthProvider>
      <App />
    </AuthProvider>

  </ApolloProvider>,
);
