import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import SocialConnection from './SocialConnection';
import PlayStoreAppStoreIcons from '../PlayStoreAppStoreIcons';

function Rider() {
  return (
    <section className="section bg-light" id="rider">
      <div className="riderContainer">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="text-center">
              <h2>
                <span className="fw-bold whiteText">
                  Rider
                  <span className="beta-tag">Beta</span>
                </span>
              </h2>
              <p className="text-muted mx-auto section-subtitle mt-3">
                Discover the authentic testimony of our valued client who placed
                their trust in us and experienced our exceptional ride service
                firsthand.
              </p>
            </div>
            <SocialConnection />
          </div>
        </div>

        <div className="row mt-4 pt-4">
          <div className="col-lg-12">
            <Carousel
              showThumbs={false}
              autoPlay
              interval={5000}
              infiniteLoop
              showArrows={false}
              showStatus={false}
              transitionTime={500}
              selectedItem={1}
            >
              <div className="carousel-item">
                <div className="img_testi">
                  <img
                    src="images/testi/testi-1.jpg"
                    alt=""
                    className="mx-auto img-thumbnail img-fluid rounded-circle"
                  />
                </div>
                <p className="client_review fst-italic mt-4 text-center text-muted">
                  @quot The app is easy to use, their customer service was awesome
                  and I was able to get my ride almost instantly@quot
                </p>
                <p className="client_name text-center mb-5 whiteText">
                  - Leah Dawit,
                </p>
              </div>
              <div className="img_testi">
                <img
                  src="images/testi/testi-1.jpg"
                  alt=""
                  className="mx-auto img-thumbnail img-fluid rounded-circle"
                />

                <p className="client_review fst-italic mb-10 text-center text-muted">
                  @quot The app is easy to use, their customer service was awesome
                  and I was able to get my ride almost instantly@quot
                </p>
                <p className="client_name text-center mb-5 whiteText">
                  - Dawit
                </p>
              </div>
              <div className="img_testi">
                <img
                  src="images/testi/testi-1.jpg"
                  alt=""
                  className="mx-auto img-thumbnail img-fluid rounded-circle"
                />
                <p className="client_review fst-italic mt-4 text-center text-muted">
                  @quot The app is easy to use, their customer service was awesome
                  and I was able to get my ride almost instantly @quot
                </p>
                <p className="client_name text-center mb-5  whiteText">
                  - Frazer Tamru
                </p>
              </div>
            </Carousel>
            <div className="text-center mt-4">
              <button type="button" className="primary-button">
                Request A Ride
              </button>
            </div>
            <PlayStoreAppStoreIcons />
          </div>
          <div className="text-center mt-4">
            <a href="#enter-testimony" className="enter-testmony">
              Enter Your Testimony
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Rider;
