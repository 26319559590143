/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useRef } from 'react';

import {
  Navigation, Pagination, A11y, Autoplay, EffectFade,
// eslint-disable-next-line import/no-unresolved
} from 'swiper/modules';

// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';

import { IntlProvider, FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCircleDot } from '@fortawesome/free-solid-svg-icons';
import useLanguageContext from '../../hooks/useLanguageContext';

import messages from '../../messages';

import PlayStoreAppStoreIcons from '../PlayStoreAppStoreIcons';

function Home() {
  // eslint-disable-next-line no-unused-vars
  const { lang, changeLang } = useLanguageContext();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const videoRef = useRef(null); // Add this line

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);

    if (videoRef.current) {
      videoRef.current.playbackRate = 1.5; // Or any rate you want
    }

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sectionStyle = isMobile ? { height: '100vh' } : {};

  const combinedStyleFirst = {
    ...sectionStyle,
    backgroundImage: 'url("images/rainy-city-streets-ai.webp")',
  };
  const combinedStyleSecond = {
    ...sectionStyle,
    backgroundImage: 'url("images/addisAbab_dark_mode.webp")',
  };
  const combinedStyleThrid = {
    ...sectionStyle,
    backgroundImage: 'url("images/black_pattern.webp")',
  };
  const combinedStyleFourth = {
    ...sectionStyle,
    backgroundImage: 'url("images/efoyy-desert.png")',
  };

  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay, EffectFade]}
        spaceBetween={50}
        slidesPerView={1}
        effect="fade"
        loop
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        navigation={{
          nextEl: '.swiper-button-next-custom',
          prevEl: '.swiper-button-prev-custom',
        }}

      >

        <SwiperSlide>
          <section
            className="section header-bg-img h-100vh align-items-center d-flex jarallax"
            data-jarallax='{"speed": 0.2}'
            style={combinedStyleFirst}
            id="home"
          >
            <div className="bg-overlay" />
            <div className="container z-index">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="text-center header-content mx-auto">
                    <h2 className=" text-white first-title mb-4 home-header">
                      <FormattedMessage id="whatIsYour" />
                      <span className="text-custom fw-bold t">
                        &nbsp;
                        <FormattedMessage id="destination" />
                      </span>
                      <span className="text-white fw-bold">
                        ?
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="rider_driver_button">
              <PlayStoreAppStoreIcons />
              <p className="text-white mx-auto header-desc mt-4">
                <FormattedMessage id="homeSlogon" />
              </p>

              {/* <p className="header-name text-white text-capitalize mb-0">
                  <FontAwesomeIcon icon={faPhone} />
                  <span className="text-custom fw-bold"> 8888</span>
                </p>  */}
            </div>

            <div className="scroll_down">
              <a href="https://efoyy.com" className="scroll">
                <FontAwesomeIcon icon={faChevronDown} aria-label="About" />
              </a>
            </div>
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <section
            className="section header-bg-img h-100vh align-items-center d-flex jarallax"
            data-jarallax='{"speed": 0.2}'
            style={combinedStyleSecond}
            id="home"
          >
            <div className="bg-overlay" />
            <div className="container z-index">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="text-center header-content mx-auto">
                    <h2 className=" text-white first-title mb-4 home-header">
                      <FormattedMessage id="connectYou" />
                      {' '}
                      <br />

                    </h2>
                    <p className="text-custom fw-bold home-bottom-text">
                      &nbsp;
                      {' '}
                      <FormattedMessage id="nearestDriver" />
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="rider_driver_button">
              <PlayStoreAppStoreIcons />
              <p className="text-white mx-auto header-desc mt-4">
                <FormattedMessage id="homeSlogon" />
              </p>

              {/* <p className="header-name text-white text-capitalize mb-0">
                  <FontAwesomeIcon icon={faPhone} />
                  <span className="text-custom fw-bold"> 8888</span>
                </p>  */}
            </div>

            <div className="scroll_down">
              <a href="https://efoyy.com" className="scroll">
                <FontAwesomeIcon icon={faChevronDown} aria-label="About" />
              </a>
            </div>
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <section
            className="section header-bg-img h-100vh align-items-center d-flex jarallax"
            data-jarallax='{"speed": 0.2}'
            style={combinedStyleThrid}
            id="home"
          >
            <div className="bg-overlay" />
            <div className="container z-index">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="text-center header-content mx-auto">
                    <h2 className=" text-white first-title mb-4 home-header">
                      <FormattedMessage id="affordableRates" />
                      {' '}
                      <br />

                    </h2>
                    <p className="text-custom fw-bold home-bottom-text">
                      &nbsp;
                      {' '}
                      <FormattedMessage id="bothWorlds" />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="rider_driver_button">
              <PlayStoreAppStoreIcons />
              <p className="text-white mx-auto header-desc mt-4">
                <FormattedMessage id="homeSlogon" />
              </p>

              {/* <p className="header-name text-white text-capitalize mb-0">
                  <FontAwesomeIcon icon={faPhone} />
                  <span className="text-custom fw-bold"> 8888</span>
                </p>  */}
            </div>

            <div className="scroll_down">
              <a href="https://efoyy.com" className="scroll">
                <FontAwesomeIcon icon={faChevronDown} aria-label="About" />
              </a>
            </div>
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <section
            className="section header-bg-img h-100vh align-items-center d-flex jarallax"
            data-jarallax='{"speed": 0.2}'
            style={combinedStyleFourth}
            id="home"
          >
            <div className="bg-overlay" />
            <div className="container z-index">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="text-center header-content mx-auto">
                    <h2 className=" text-white first-title mb-4">
                      <FormattedMessage id="OurVisionIs" />
                      {' '}
                      <br />
                    </h2>
                    <p className="text-custom fw-bold home-bottom-text home-header">
                      &nbsp;
                      {' '}
                      <FormattedMessage id="toDelever" />
                    </p>

                  </div>
                </div>
              </div>
            </div>
            <div className="rider_driver_button">

              <p className="text-white mx-auto header-desc mt-4">
                <FormattedMessage id="whatIsEfoyy" />
              </p>

            </div>

          </section>

        </SwiperSlide>
        <div className="swiper-button-next-custom"><FontAwesomeIcon icon={faCircleDot} /></div>
        <div className="swiper-button-prev-custom"><FontAwesomeIcon icon={faCircleDot} /></div>
      </Swiper>
    </IntlProvider>

  );
}

export default Home;
