import React from 'react';
import PropTypes from 'prop-types';
import Header from '../SOLID/header/Header';
import Footer from './Footer';

function MainLayout({ children }) {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}
MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default MainLayout;
