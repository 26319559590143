import React from 'react';
import SocialConnection from './SocialConnection';

function Contact() {
  const fullScreenStyle = {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0, // Add this line to remove any default margins
    padding: 0, // Add this line to remove any default padding
  };

  return (

    <section className="section bg-light" id="contact" style={fullScreenStyle}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="text-center">
              <h2>
                <span className="fw-bold whiteText">
                  Contact Us
                  <span className="beta-tag">Beta</span>
                </span>
                {' '}
              </h2>
              <p className="text-muted mx-auto section-subtitle mt-3" />
            </div>
          </div>
        </div>
        <SocialConnection />
        <div className="row mt-5">
          <div className="col-lg-4">
            <div className="text-center">

              <div className="mt-3">
                <h5 className="mb-0 contact_detail-title fw-bold whiteText">Call Us On</h5>
                <p className="text-muted whiteText">0911877477 | 613-501-4835</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="text-center">
              <div>
                <i className="mbri-letter text-custom h1" />
              </div>
              <div className="mt-3">
                <h5 className="mb-0 contact_detail-title fw-bold whiteText">Email Us At</h5>
                <p className="text-muted whiteText">support@efoyy.com</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="text-center">
              <div>
                <i className="mbri-pin text-custom h1" />
              </div>
              <div className="mt-3">
                <h5 className="mb-0 contact_detail-title fw-bold whiteText">Visit Office</h5>
                <p className="text-muted whiteText">Coming Soon...</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-12">
            <div className="form-efoyy contact_form">
              <div id="message" />
              <form method="post" action="php/contact.php" name="contact-form" id="working_form">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mt-2">
                      <input name="name" id="name" type="text" className="form-control" placeholder="Your name..." />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mt-2">
                      <input name="email" id="email" type="email" className="form-control" placeholder="Your email..." />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mt-2">
                    <input type="text" className="form-control" id="subject" placeholder="Your Subject.." />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mt-2">
                      <textarea name="comments" id="comments" rows="4" className="form-control" placeholder="Your message..." />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 text-end mt-2">
                    <input type="submit" id="submit" name="send" className="submitBnt btn btn-custom text-uppercase" value="Send Massage" />
                    <div id="simple-msg" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}

export default Contact;
