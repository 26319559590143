import React, { createContext, useState, useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

const LanguageSelectorContext = createContext();

function Provider({ children }) {
  const [lang, setLang] = useState(localStorage.getItem('selectedLanguage') || 'en-US');

  // Memoize the value object
  const valueToShare = useMemo(() => ({
    lang,
    changeLang: (newLang) => {
      setLang(newLang);
      localStorage.setItem('selectedLanguage', newLang);
    },
  }), [lang]);

  return (
    <LanguageSelectorContext.Provider value={valueToShare}>
      {children}
    </LanguageSelectorContext.Provider>
  );
}

export { Provider };
export default LanguageSelectorContext;

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};
