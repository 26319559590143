import React from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';

import useLanguageContext from '../../hooks/useLanguageContext';

import messages from '../../messages';

function Company() {
  // eslint-disable-next-line no-unused-vars
  const { lang, changeLang } = useLanguageContext();
  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <section className=" companyContainer bg-light" id="company">

        <div className="square squareBG1 col-12 col-md-6">
          <div className="content">
            <h5 className="company-title">Our Vision</h5>
            <div className="vision-title-border" />
            <p className="mt-3 company-content">
              <FormattedMessage id="vision" />
            </p>
          </div>
        </div>

        <div className="square squareBG2 col-12 col-md-6">

          <div className="content">
            <h5 className="company-title">Our Mission</h5>
            <div className="vision-title-border" />
            <p className=" mt-3 company-content">
              <FormattedMessage id="mission" />
            </p>
          </div>
        </div>

        <div className="square squareBG3 col-12 col-md-6">
          <div className="content">
            <h5 className="company-title">Our Values</h5>
            <div className="vision-title-border" />
            <p className=" mt-3 company-content">
              <FormattedMessage id="values" />
            </p>
          </div>
        </div>

        <div className="square squareBG4 col-12 col-md-6">
          <div className="content">
            <h5 className="company-title">Our Team</h5>
            <div className="vision-title-border" />
            <p className="  mt-3 company-content">
              <FormattedMessage id="team" />

            </p>
            <p className="  mt-3 company-content">
              <FormattedMessage id="team2" />
            </p>
            <button type="button" className="primary-button" aria-label="View Job Openings">
              <FormattedMessage id="careers" />
            </button>
          </div>
        </div>
        {/* <div className="logo-circle">
          <span>ፎ</span>
         <img src="../../../../public/images/efoyyLogo.svg" alt="Logo" />
        </div> */}

      </section>
    </IntlProvider>
  );
}

export default Company;
