// BasicLayout.js
import React from 'react';
import PropTypes from 'prop-types';

function BasicLayout({ children }) {
  return (
    <div>
      {children}
    </div>
  );
}

BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default BasicLayout;
