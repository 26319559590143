/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import {
  Navigation, Pagination, A11y, Autoplay,
// eslint-disable-next-line import/no-unresolved
} from 'swiper/modules';

// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';

import { IntlProvider, FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStarOfLife, faHeart, faRocket, faGem,
} from '@fortawesome/free-solid-svg-icons';

import useLanguageContext from '../../hooks/useLanguageContext';

import messages from '../../messages';

function Features() {
  // eslint-disable-next-line no-unused-vars
  const { lang, changeLang } = useLanguageContext();
  const [slidesPerView, setSlidesPerView] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      setSlidesPerView(window.innerWidth > 768 ? 3 : 1);
    };

    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <section className="features-swiper-container section " id="features">

        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="text-center">
              <h3 className="whiteText">
                <span className="fw-bold whiteText">
                  Features
                  <span className="beta-tag">Beta</span>
                </span>
              </h3>
              <p className="text-muted mx-auto section-subtitle mt-3">
                Centered Around You: Embrace
                Human-Centered Design Thinking for Optimal Features!
              </p>
            </div>
          </div>
        </div>

        <Swiper
          modules={[Navigation, Pagination, A11y, Autoplay]}
          spaceBetween={0}
          slidesPerView={slidesPerView}
          effect="fade"
          loop
          pagination={{
            clickable: true,

          }}
          scrollbar={{ draggable: true }}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: '.swiper-button-next-custom',
            prevEl: '.swiper-button-prev-custom',
          }}
        >
          <SwiperSlide>
            <section
              className="section h-100vh align-items-center d-flex slide-box-black"
              id="home"
            >
              <div className="bg-overlay" />
              <div className="container z-index">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="text-center header-content mx-auto  ">
                      <h3 className=" text-white first-title mb-4 home-header">
                        <span><FontAwesomeIcon icon={faGem} aria-label="Unique value" /></span>
                        <FormattedMessage id="uniqueValue" />
                      </h3>
                      <p>
                        {' '}
                        {/* <FormattedMessage id="gamification" /> */}
                        {' '}
                      </p>
                      <p>
                        {' '}
                        <FormattedMessage id="multiplePricingModels" />
                        {' '}
                      </p>
                      <p>
                        {' '}
                        <FormattedMessage id="aiComingSoon" />
                        {' '}
                      </p>
                      <p>
                        {' '}
                        <FormattedMessage id="telegramBookingComingSoon" />
                        {' '}
                      </p>
                      <p>
                        {' '}
                        <FormattedMessage id="efoytaTime" />
                      </p>
                      <p>
                        {' '}
                        <FormattedMessage id="customization" />
                      </p>
                      <p>
                        {' '}
                        <FormattedMessage id="lightAndDarkMode" />
                      </p>
                      <p>
                        {' '}
                        <FormattedMessage id="dynamicFuelPriceAdjustment" />
                        {' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </section>
          </SwiperSlide>

          <SwiperSlide>
            <section
              className="section  h-100vh align-items-center d-flex slide-box-black"
              id="home"
            >
              <div className="bg-overlay" />
              <div className="container z-index">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="text-center header-content mx-auto">
                      <h3 className=" text-white first-title mb-4 home-header">
                        <span><FontAwesomeIcon icon={faRocket} aria-label="Pro" /></span>
                        <FormattedMessage id="pro" />
                      </h3>
                      <p><FormattedMessage id="multiplePaymentGatewayIntegration" /></p>
                      <p>Enhanced Easy pickup + Added Security  </p>
                      <p>Stay Tuned! 🌟 - Full list will be available soon   </p>
                      {/* <p><FormattedMessage id="driverPerks" /></p>
                      <p><FormattedMessage id="weeklyLotteryDraw" /></p>
                      <p><FormattedMessage id="nearestPoliceStation" /></p>
                      <p><FormattedMessage id="nearestHealthFacilities" /></p> */}
                      <p> </p>
                    </div>
                  </div>
                </div>
              </div>

            </section>
          </SwiperSlide>

          <SwiperSlide>
            <section
              className="section  h-100vh align-items-center d-flex slide-box-black"
              id="home"
            >
              <div className="bg-overlay" />
              <div className="container z-index">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="text-center  mx-auto">
                      <h3 className=" text-white first-title mb-4 home-header">
                        <span><FontAwesomeIcon icon={faHeart} aria-label="Essential" /></span>
                        <FormattedMessage id="Essential" />
                      </h3>
                      <p><FormattedMessage id="sos" /></p>
                      <p><FormattedMessage id="easyRegistration" /></p>
                      <p><FormattedMessage id="accurateNearestDriverFinder" /></p>
                      <p><FormattedMessage id="requestFemaleOnlyDriver" /></p>
                      <p>Request Wheel chair And Child Seat</p>
                      <p><FormattedMessage id="languageSupport" /></p>

                    </div>
                  </div>
                </div>
              </div>

            </section>
          </SwiperSlide>

          <SwiperSlide>
            <section
              className="section  h-100vh align-items-center d-flex slide-box-black"
              id="home"
            >
              <div className="bg-overlay" />
              <div className="container z-index">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="text-center header-content mx-auto">
                      <h3 className=" text-white first-title mb-4 home-header">
                        <span><FontAwesomeIcon icon={faStarOfLife} aria-label="Basic" /></span>
                        <FormattedMessage id="basic" />
                      </h3>
                      <p>Google Map Integration</p>
                      <p>Live Tracking</p>
                      <p>iOS and Andriod app</p>
                      <p>Call Center </p>
                      <p>Multi Language support</p>
                    </div>
                  </div>
                </div>
              </div>

            </section>
          </SwiperSlide>
          <SwiperSlide>
            <section
              className="section  h-100vh align-items-center d-flex slide-box-black"
              id="home"
            >
              <div className="bg-overlay" />
              <div className="container z-index">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="text-center header-content mx-auto">
                      <h3 className=" text-white first-title mb-4 home-header">
                        <span><FontAwesomeIcon icon={faStarOfLife} aria-label="Basic" /></span>
                        <FormattedMessage id="techInnovation" />
                      </h3>

                      <p><FormattedMessage id="scalable" /></p>
                      <p><FormattedMessage id="modernAPI" /></p>
                      <p>Security Certified</p>
                      <p><FormattedMessage id="cloudIntegration" /></p>
                      <p><FormattedMessage id="ciCdAutomation" /></p>
                      {/* <p><FormattedMessage id="aaaRatingAspiration" /></p> */}
                      <p><FormattedMessage id="robustTechnology" /></p>
                    </div>
                  </div>
                </div>
              </div>

            </section>
          </SwiperSlide>

        </Swiper>
      </section>
    </IntlProvider>
  );
}

export default Features;
