/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable comma-dangle */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import GridLoader from 'react-spinners/GridLoader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import SocialConnection from './frontPage/SocialConnection';
import PlayStoreAppStoreIcons from './PlayStoreAppStoreIcons';

// In your component file
import useVerifyPhoneNumberHook from '../graphql/operations/auth'; // Adjust the import path accordingly

function Login() {
  const {
    // eslint-disable-next-line no-unused-vars
    register, handleSubmit, formState: { errors }, reset,
  } = useForm();
  const [step, setStep] = useState(1); // Step 1: Phone Number, Step 2: OTP
  const [serverError, setServerError] = useState('');
  const [verifyPhoneNumber, { data, loading, error }] = useVerifyPhoneNumberHook();

  const onSubmitPhoneNumber = async (formData) => {
    verifyPhoneNumber({
      variables: {
        userInput: {
          phoneNumber: formData.phoneNumber,
          visibility: 'public',
        },
      },
      onCompleted: (data) => {
        setServerError('');
        if (data.verifyPhoneNumber.status !== 200) {
          // Set server error message if status is not 200
          setServerError(data.verifyPhoneNumber.errorMessage);
        } else {
          setStep(2); // Move to next step if no error
        }
      },
      onError: (apolloError) => {
        // Optionally handle GraphQL errors
        console.log(apolloError);
      },
    });
  };

  if (error) {
    console.log(error);
  }

  console.log(data);

  const onSubmitOTP = async (data) => {
    console.log(data); // This will log otp1, otp2, otp3, otp4
    // Combine OTP inputs and verify OTP here
    // After verification, proceed with the login process
  };

  return (
    <div className="loginContainer">
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="card login-box">
              <div className="card-header">
                <h4>
                  Login
                  <span className="beta-tag">Beta</span>
                </h4>
              </div>
              <div className="card-body">
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <GridLoader color="#FF6F61" loading={loading} size={15} />
                  </div>
                ) : (
                  <>
                    {serverError && (
                    <p className="text-danger">
                      <FontAwesomeIcon icon={faExclamationCircle} />
                      {' '}
                      {serverError}
                    </p>
                    )}
                    {step === 1 && (
                    <form onSubmit={handleSubmit(onSubmitPhoneNumber)}>
                      <div className="mb-3">
                        <label htmlFor="phoneNumber" className="form-label login-label-name">Phone Number</label>
                        <input
                          type="text"
                          className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                          id="phoneNumber"
                          {...register('phoneNumber', { required: true })}
                        />
                        {errors.phoneNumber && <div className="invalid-feedback">Phone Number is required</div>}
                        {error && <div className="alert alert-danger" role="alert">{error.message}</div>}
                      </div>
                      <button type="submit" className="btn primary-button " style={{ padding: '10px 15px', fontSize: '14px', margin: '5px' }}>Send OTP</button>
                    </form>

                    )}
                    {step === 2 && (
                    <form onSubmit={handleSubmit(onSubmitOTP)}>
                      <div className="mb-3">
                        <label htmlFor="otp" className="form-label login-label-name">
                          OTP
                          <span style={{ fontSize: '0.8rem', marginLeft: '5px' }}>
                            ( OTP has been sent to your efoyy app. )
                          </span>
                        </label>
                        <div className="d-flex justify-content-between">
                          {Array.from({ length: 4 }).map((_, index) => (
                            <input
                              key={index}
                              type="text"
                              className={`form-control mx-1 ${errors[`otp${index + 1}`] ? 'is-invalid' : ''}`}
                              maxLength="1"
                              style={{ width: '70px', padding: '5px', fontSize: '14px' }}
                              {...register(`otp${index + 1}`, { required: true })}
                            />
                          ))}
                        </div>
                        {errors.otp && <div className="invalid-feedback">OTP is required</div>}
                      </div>
                      <button type="submit" className="btn primary-button " style={{ padding: '10px 15px', fontSize: '14px', margin: '5px' }}>Verify OTP</button>
                    </form>
                    )}
                  </>
                )}
              </div>
              <div className="login-disclaimer">
                <p>
                  This feature is currently in beta. We appreciate
                  your feedback to improve it.
                </p>
              </div>
              <div style={{ paddingBottom: '20px' }}>
                <SocialConnection />
              </div>
            </div>

            <div style={{ paddingBottom: '20px' }}>
              <PlayStoreAppStoreIcons />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
